<template>
  <div class="vice">
    <div class="main_total">
      <div class="flex_center main_total_bg">
        <span>智能推荐（{{ dataObj.total }}）</span>
      </div>
    </div>
    <div class="main_table" v-if="dataObj.total">
      <div class="flex main_table_item" v-for="item in dataObj.records" :key="item.id" @click="toDetail(item)">
        <div class="main_table_item_l">
          <div class="flex_center main_table_item_title">
            <i class="main_table_item_title_mark"></i>
            <span>{{ item.customtitle || item.projectName || item.noticeTitle }}</span>
          </div>
          <div class="flex_row_center main_table_item_info">
            <div class="flex_center main_table_item_info_tag">
              <template v-if="webType !== 'listPageWithPrj'">
                <div class="flex_center main_table_item_info_tag_type main_table_item_info_tag_type1"
                     v-if="item.zbdateStatus"
                     :style="item.zbdateStatus === '最新' ? '' :
                     item.zbdateStatus === '快开标' ? 'background-color: #FFE5E5; color: #FF0000;' :
                     item.zbdateStatus === '已开标' ? 'background-color: #EEEEEE; color: #888888;' : ''">
                  <span>{{ item.zbdateStatus }}</span>
                </div>
                <div v-if="item.industry" class="flex_center main_table_item_info_tag_type main_table_item_info_tag_type2">
                  <span>{{ item.industry }}</span>
                </div>
                <div v-if="item.industryv2" class="flex_center main_table_item_info_tag_type main_table_item_info_tag_type3">
                  <span>{{ item.industryv2 || '-' }}</span>
                </div>
              </template>
              <div class="flex_center main_table_item_info_tag_amount" v-else>
                <span>预算金额：{{item.budgetMoney | priceFilter()}}</span>
              </div>
            </div>
            <div class="flex_center main_table_item_info_time">
              <template v-if="webType !== 'listPageWithPrj'">
                <i class="el-icon-location-outline"></i>
                <span>{{ item.province | provinceFilter() }} {{ item.city ? '·' + item.city : '' }}</span>
                <div class="main_table_item_info_link"></div>
                <span>{{item.publishDate | timeFilter()}}</span>
              </template>
              <span v-else>发布时间：{{item.publishDate | timeFilter()}}</span>
            </div>
          </div>
        </div>
        <div
          class="flex_row_center main_table_item_r"
          :style="(item.erpTbProjectId === null && item.zbdateStatus === '已开标') ? 'color: #888888;' :
          item.erpTbProjectId !== null ? 'color: #20B84E;' : ''"
          @click.stop="onManagement(item)">
          {{ item.erpTbProjectId === null ? '加入管理' : '进入管理' }}
        </div>
      </div>
      <div class="flex main_table_pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="size"
          layout="prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <el-empty description="暂无数据" v-else></el-empty>
  </div>
</template>

<script>
  export default {
    name: "recruit-table",
    data() {
      return {
        currentPage: 1,
        size: 20,
        total: 0,
        webType: ''
      }
    },
    props: {
      dataObj: {
        type: Object,
        required: true,
      }
    },
    watch: {
      dataObj: {
        handler(val) {
          this.size = val.size;
          this.total = val.total;
          this.webType = val.webType
        },
        deep: true
      }
    },
    filters: {
      priceFilter(price) {
        if (!price) {
          return
        }
        if (price === 0 || price === null) return '-'
        if (price < 10000) return price + '元'
        return (price / 10000).toFixed(1) + '万元'
      },
      timeFilter(time) {
        if (!time) return "-"
        let index = time.indexOf(" ")
        let pulishTime = ""
        if (index > 0) {
          pulishTime = time.substring(0, index).replaceAll("-", "-")
        } else {
          pulishTime = time
        }
        return pulishTime
      },
      provinceFilter(province) {
        let provinces = province.replace(/广西壮族自治区/g, '广西')
        return provinces
      }
    },
    mounted() {
    },
    methods: {
      // 加入管理
      onManagement(e) {
        const { protocol, host } = window.location;
        const domain = `${protocol}//${host}`;
        if (e.erpTbProjectId === null && e.zbdateStatus !== '已开标') {
          //  加入管理的地址
          window.open(`${domain}/bxbh/#/bidder/allProjects?tenderingCode=${e.tenderingCode}`, "_blank");
          // window.open(`http://localhost:8192/#/bidder/allProjects?tenderingCode=${e.tenderingCode}`, '_blank');
        } else if (e.erpTbProjectId !== null) {
          //  进入管理的地址
          window.open(`${domain}/bxbh/#/bidder/manageProjects`, "_blank");
          // window.open(`http://localhost:8192/#/bidder/manageProjects`, '_blank');
        }
      },
      // 跳转详情页
      toDetail(e) {
        let id = this.webType === 'listPageWithPrj' ? e.projectUuid : e.tenderingCode;
        this.$emit('toDetail', id)
      },
      handleCurrentChange(val) {
        this.$emit('handleCurrentChange', val)
      }
    }
  }
</script>

<style scoped lang="less">
  .vice {
    .main_total {
      background-image: url(~@/assets/images/bjtsy/bg_2.png);
      background-repeat: no-repeat;
      background-size: cover;
      width: 520px;
      min-width: 520px;
      height: 40px;
      .main_total_bg {
        width: 100%;
        height: 100%;
        padding: 0 20px;
        background: linear-gradient(90deg, rgba(24, 144, 255, 0.8) 0%, rgba(255, 255, 255, 0.2) 100%);
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }
    }
    .main_table {
      .main_table_item {
        padding: 16px 0 16px 20px;
        border-bottom: 1px solid #DCDCDC;
        cursor: pointer;
        .main_table_item_l {
          flex: 1;
          padding-right: 30px;
          .main_table_item_title {
            font-size: 16px;
            color: #212121;
            text-align: left;
            .main_table_item_title_mark {
              width: 6px;
              min-width: 6px;
              height: 6px;
              background-color: #FF0000;
              border-radius: 50%;
              margin-right: 8px;
            }
          }
          .main_table_item_info {
            justify-content: space-between;
            padding-top: 16px;
            .main_table_item_info_tag {
              .main_table_item_info_tag_type {
                height: 26px;
                border-radius: 4px;
                padding: 0 6px;
                margin-right: 12px;
                font-size: 14px;
                line-height: 1;
              }
              .main_table_item_info_tag_type1 {
                color: #23B35E;
                background-color: #E8F9F7;
              }
              .main_table_item_info_tag_type2 {
                color: #ED8606;
                background-color: #fff;
                border: 1px solid #ED8606;
              }
              .main_table_item_info_tag_type3 {
                color: #1890FF;
                background-color: #fff;
                border: 1px solid #1890FF;
              }
              .main_table_item_info_tag_amount {
                font-size: 16px;
                color: #4E4E4E;
              }
            }
            .main_table_item_info_time {
              font-size: 14px;
              color: #4E4E4E;
              line-height: 1;
              > i {
                padding-right: 4px;
                font-size: 16px;
                color: #1890FF;
              }
              .main_table_item_info_link {
                width: 1px;
                height: 16px;
                margin: 0 12px;
                background-color: #4E4E4E;
              }
            }
          }
        }
        .main_table_item_r {
          position: relative;
          width: 130px;
          min-width: 130px;
          font-size: 16px;
          color: #1890FF;
        }
        .main_table_item_r::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 20px;
          background-color: #DCDCDC;
        }
      }
      .main_table_pagination {
        justify-content: flex-end;
        padding: 20px 0;
      }
    }
  }
</style>
